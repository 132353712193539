.icon-button {
  @apply text-black dark:text-white;
  display: inline-flex;
  align-items: center;
  padding: 0;
  color: var(--primary-text-color);
  border: 0;
  background: transparent;
  cursor: pointer;
  transition: 100ms ease-in;
  opacity: 0.4;

  &__text {
    padding-left: 2px;
  }

  &:hover,
  &:active,
  &:focus {
    opacity: 0.6;
    transition: color 200ms ease-out;
  }

  &.disabled {
    opacity: 0.2;
    cursor: default;
  }

  &.active {
    color: var(--highlight-text-color);
    opacity: 1;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus,
  &:active {
    outline: 0 !important;
  }
}

.invisible {
  font-size: 0 !important;
  line-height: 0 !important;
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;

  img,
  svg {
    margin: 0 !important;
    border: 0 !important;
    padding: 0 !important;
    width: 0 !important;
    height: 0 !important;
  }
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.ellipsis::after { content: '…'; }

.image-loader {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .image-loader__preview-canvas {
    max-width: $media-modal-media-max-width;
    max-height: $media-modal-media-max-height;
    background: url('../assets/images/void.png') repeat;
    object-fit: contain;
  }

  &.image-loader--amorphous .image-loader__preview-canvas {
    display: none;
  }
}

.zoomable-image {
  @apply relative w-full h-full flex items-center justify-center;

  img {
    @apply w-auto h-auto object-contain shadow-2xl;
    max-width: $media-modal-media-max-width;
    max-height: $media-modal-media-max-height;
  }
}

.react-swipeable-view-container {
  &,
  .column {
    height: 100%;
  }
}

.react-swipeable-view-container > * {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ui {
  display: block;
  width: 100%;
  padding: 0 0 calc(var(--thumb-navigation-height) + 86px);

  .page {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.slist__append {
  flex: 1 1 auto;
  position: relative;
  padding: 30px 15px;
}

.setting-text {
  color: var(--primary-text-color--faint);
  background: transparent;
  border: 0;
  border-bottom: 2px solid var(--brand-color);
  box-sizing: border-box;
  display: block;
  font-family: inherit;
  margin-bottom: 10px;
  padding: 7px 0;
  width: 100%;

  &:focus,
  &:active {
    color: var(--primary-text-color);
    border-bottom-color: var(--highlight-text-color);
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
}

.text-btn {
  display: inline-block;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border: 0;
  background: transparent;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
}

@keyframes flicker {
  0% { opacity: 1; }
  30% { opacity: 0.75; }
  100% { opacity: 1; }
}

.text-muted {
  color: var(--gray-500);
}

.mb-10 {
  margin-bottom: 10px;
}
